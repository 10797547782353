import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import MarkdownContent from "../components/MarkdownContent";

export default function Hero(props) {
  const {
    // height = 500,
    img,
    title,
    subheading,
    imgPosition = "bottom center ",
  } = props;

  return (
    <React.Fragment>
      <div
        className="container-fluid px-0 mt-0 pt-md-0 bg-black d-grid"
        style={{
          alignItems: "center",
          // maxWidth: "950px",
        }}
      >
        {img?.url ? (
          <img
            src={img}
            className="img-hero"
            objectFit={"contain"}
            objectPosition={imgPosition}
            style={{
              // gridArea: "1/1",
              // You can set a maximum height for the image, if you wish.
              // height: height,
              width: "100%",
            }}
            // You can optionally force an aspect ratio for the generated image
            // aspectratio={3 / 1}
            // This is a presentational image, so the alt should be an empty string
            alt=""
            formats={["auto", "webp", "avif"]}
          />
        ) : (
          <GatsbyImage
            image={img}
            className="img-hero"
            objectFit={"contain"}
            objectPosition={imgPosition}
            loading={'eager'}
            style={{
              // gridArea: "2/1",
              // You can set a maximum height for the image, if you wish.
              // maxHeight: height,
            }}
            // layout="fullWidth"
            // You can optionally force an aspect ratio for the generated image
            // aspectratio={3 / 1}
            // This is a presentational image, so the alt should be an empty string
            alt=""
            formats={["auto", "webp", "avif"]}
          />
        )}
        {(title || subheading) && (
          <div
            className="container mt-xl-3 mt-xxl-4"
            style={{
              // By using the same grid area for both, they are stacked on top of each other
              gridArea: "1/1",
              position: "relative",
              // This centers the other elements inside the hero component
              placeItems: "center",
              alignSelf: "flex-start",
              display: "grid",
            }}
          >
            {/* Any content here will be centered in the component */}
            {title && (
              <>
                <div className="hero-content offset-md-8 offset-lg-7 offset-xl-8 offset-xxl-6 col-md-6 col-xl-4 col-lg-5 col-xl-5 col-xxl-4 mt-3 px-3">
                  <h1 className="mb-3">{title}</h1>
                  { subheading && <MarkdownContent className="mb-3" content={subheading} /> }
                  <p>
                    <Link to="/portfolio/available/" className="icon-arrow">
                      Explore the gallery
                      <span className="bi bi-arrow-right-short fs-4 align-middle"></span>
                    </Link>
                  </p>
                </div>
              </>
            )}
            {/* {subheading && (
              <p
                className=""
                style={{
                  backgroundColor: "rgb(255, 68, 0)",
                  color: "white",
                  lineHeight: "1",
                  padding: "0.25rem",
                  marginTop: "0.5rem",
                }}
              >
                {subheading}
              </p>
            )} */}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

Hero.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  height: PropTypes.number,
  subheading: PropTypes.string,
};
