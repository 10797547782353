import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage, withArtDirection } from "gatsby-plugin-image";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import Hero from "../components/Hero";
import ContactForm from "../components/ContactForm";
import MarkdownContent from "../components/MarkdownContent";

// eslint-disable-next-line
export const IndexPageTemplate = ({
  helmet,
  title,
  hero,
  process,
  about,
}) => {
  const heroImageDefault = getImage(hero.image) || null;
  const heroImageLarge = getImage(hero.imageLarge) || null;

  let heroImage = (heroImageLarge && !hero.imageLarge.url)
    ? withArtDirection(heroImageLarge, [
        {
          media: "(max-width: 991px)",
          image: heroImageDefault,
        },
        {
          media: "(min-width: 1700px)",
          image: heroImageDefault,
        },
      ])
    : heroImageDefault;

  // pass a single image for Netlify CMS image (art directed not supported)
  if (hero.image.url || hero.imageLarge.url) {
    heroImage = hero.image || hero.imageLarge;
  }

  return (
    <article className="page page--home no-spacing">
      {helmet || ""}
      <h1 className="visually-hidden">{title}</h1>
      <section
        id="section-hero"
        className="bg-black text-light mt-0 mb-5 pt-sm-4 pt-md-5 pt-lg-0"
      >
        <Hero
          img={heroImage}
          title={hero.heading}
          subheading={hero.description}
        />
      </section>
      <section id="section-process" className="container mb-5">
        <div className="row">
          <div className="col-md-8 mb-4">
            <div
              className="ratio ratio-16x9"
            >
              <iframe
                src={process.embed.url || "https://player.vimeo.com/video/15439151?h=80ae4eab64&title=0&byline=0&portrait=0"}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                referrerPolicy="origin-when-cross-origin"
                title={process.embed.title || "Video Player"}
              ></iframe>
            </div>
            {/* <script src="https://player.vimeo.com/api/player.js"></script> */}
          </div>
          <div className="col-md-4">
            <h2 className="h1">{process.heading}</h2>
            <MarkdownContent className="process-description" content={process.description} />
            <p>
              <Link to="/process" className="icon-arrow">
                See how it's done
                <span className="bi bi-arrow-right-short fs-4 align-middle"></span>
              </Link>
            </p>
          </div>
        </div>
      </section>
      <section id="section-about-contact" className="container mb-5">
        <div className="row">
          <div className="col-md-6 mb-5">
            <h2 className="h1">{about.heading}</h2>
            <div className="row">
              <div className="col-md-8">
                <MarkdownContent className="about-description" content={about.description} />
                <p>
                  <Link to="/about" className="icon-arrow">
                    Meet David
                    <span className="bi bi-arrow-right-short fs-4 align-middle"></span>
                  </Link>
                </p>
              </div>
              <div className="col-4">
                <PreviewCompatibleImage imageInfo={about.image} />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <h2 className="h1">Contact</h2>
            <ContactForm />
          </div>
        </div>
      </section>
    </article>
  );
};

IndexPageTemplate.propTypes = {
  // image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  description: PropTypes.string,
  heading: PropTypes.string,
  hero: PropTypes.object,
  process: PropTypes.object,
  about: PropTypes.object,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        hero={frontmatter.hero}
        process={frontmatter.process}
        description={frontmatter.description}
        about={frontmatter.about}
        helmet={
          <Helmet titleTemplate="%s">
            <title>{frontmatter.title}</title>
            {frontmatter.title ? <meta property="og:title" content={frontmatter.title} /> : null}
          </Helmet>
        }
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        # heading
        description
        hero {
          heading
          description
          image {
            childImageSharp {
              gatsbyImageData(
                # quality: 90
                placeholder: NONE
                layout: CONSTRAINED
              )
            }
          }
          imageLarge {
            childImageSharp {
              gatsbyImageData(
                # quality: 90
                placeholder: NONE
                layout: CONSTRAINED
              )
            }
          }
        }
        process {
          heading
          description
          embed {
            title
            url
          }
        }
        about {
          heading
          description
          image {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 166, quality: 92, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`;
