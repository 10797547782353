import React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby-link";

const ContactForm = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false, message: props.message};
  }

  encode = (data) => {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <form
        name="contact"
        method="post"
        action="/contact/thanks/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        <div hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={this.handleChange} />
          </label>
        </div>
        <div className="form-floating mb-3">
          <input
            className="form-control"
            placeholder="Name"
            type={"text"}
            name={"name"}
            onChange={this.handleChange}
            id={"name"}
            required={true}
          />
          <label htmlFor={"name"}>Name</label>
        </div>
        <div className="form-floating mb-3">
          <input
            className="form-control"
            placeholder="name@example.com"
            type={"email"}
            name={"email"}
            onChange={this.handleChange}
            id={"email"}
            required={true}
          />
          <label htmlFor={"email"}>Email</label>
        </div>
        <div className="form-floating mb-3">
          <textarea
            className="form-control h-auto"
            placeholder="Leave a message here"
            rows="4"
            name={"message"}
            onChange={this.handleChange}
            id={"message"}
            required={true}
            value={this.state.message}
          ></textarea>
          <label htmlFor={"message"}>Message</label>
        </div>
        <div className="field">
          <button className="btn btn-outline-dark" type="submit">
            {this.props.buttonLabel || 'Contact David'}
          </button>
        </div>
      </form>
    );
  }
};

ContactForm.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      js: PropTypes.boolean,
      buttonLabel: PropTypes.string,
    })
  ),
};

export default ContactForm;
